import React, { useEffect } from 'react';

import { isSkeletonPlaceholder, Skeleton } from '~/shared/components/Skeleton';

import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/features/reportCardBuilder';

import { CUSTOM_REPORT_CHART_SETTINGS_FORM_ID } from '../../constants';
import { useCalculateCustomReportChartMutation } from '../../gql/mutations/calculateCustomReportChart.graphql';
import { mapCustomReportChartSettingsToForm } from '../../helpers';
import { useCustomReportChartSettingsCRUD } from '../../hooks';
import { CustomReportChart } from '../CustomReportChart';
import { CustomReportChartSettingsForm } from '../CustomReportChartSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If passed, renders an existing chart from customReport settings, new chart otherwise
   */
  chartIndex?: number;
  /**
   * Custom report to take settings from
   */
  customReport: CustomReportDetailedFragment;
  /**
   * Launch result to render chart
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
  /**
   * Called, when we cancel of finish creation
   */
  onCreationFinish?: (
    chartData:
      | CustomReportLaunchResultFragment['charts'][number]
      | undefined
      | null
  ) => void;
}

export const CustomReportChartCard: React.FC<Props> = ({
  className,
  chartIndex,
  customReport,
  customReportLaunchResult,
  onCreationFinish,
}) => {
  const [
    calculateCustomReportChart,
    { reset: resetCalculateCustomReportChartMutation },
  ] = useCalculateCustomReportChartMutation();

  const isNew = chartIndex === undefined;

  const chartSettingsId = isNew
    ? undefined
    : customReport.chartsSettings?.at(chartIndex)?.id;

  const {
    cardRef,
    createCustomReportChartSettings,
    isCreateCustomReportChartSettingsLoading,

    updateCustomReportChartSettings,
    isUpdateCustomReportChartSettingsLoading,

    deleteCustomReportChartSettings,
  } = useCustomReportChartSettingsCRUD({
    customReport,
  });

  const {
    initialReportData,
    currentReportData,

    initialSettings,
    requestedSettings,

    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [customReport, customReportLaunchResult],

    getReportData: ([, customReportLaunchResultDep]) =>
      isNew
        ? null
        : (customReportLaunchResultDep.charts?.at(chartIndex) ?? null),
    getReportSettings: ([customReportDep]) =>
      isNew ? null : (customReportDep.chartsSettings?.at(chartIndex) ?? null),
    mapReportSettingsToForm: mapCustomReportChartSettingsToForm,

    getIsReportEmpty: () => isNew,
    emptyReportData: null,

    untrackedFields: ['name'],

    calculateReport: (settingsInput, mutationContext) =>
      calculateCustomReportChart({
        variables: {
          customReportHashID: customReportLaunchResult.hashID,
          settingsInput,
        },
        context: {
          ...mutationContext,
          shouldSkipErrorNotifications: true,
        },
      }).then(({ data }) => {
        if (data?.calculateCustomReportChart.__typename) {
          setCurrentReportData(data.calculateCustomReportChart);
        }
      }),

    setReportSettings: settingsForm => {
      if (isNew) {
        createCustomReportChartSettings(settingsForm).then(() => {
          // This will delete the temporary card
          onCreationFinish?.(
            isSkeletonPlaceholder(currentReportData)
              ? undefined
              : currentReportData
          );
        });
        // Return undefined, cause we don't need default custom reports builder card saving behavior
        return undefined;
      }

      if (chartSettingsId) {
        return updateCustomReportChartSettings(chartSettingsId, settingsForm);
      }

      return undefined;
    },

    onCardEditCancel: () => {
      resetCalculateCustomReportChartMutation();

      if (isNew) {
        onCreationFinish?.(undefined);
      }
    },
  });

  // If the custom report launch changes, we should reset chartData, cause we can receive errors there
  useEffect(() => {
    setCurrentReportData(initialReportData);
  }, [customReportLaunchResult]);

  return (
    <ReportCardBuilderCard
      {...{
        ref: cardRef,
        title: initialSettings?.name,
        updatingTitle: 'Настройки диаграммы',
        creatingTitle: 'Создание диаграммы',
        className,
        ...customReportCardProps,
        submitButtonProps: {
          form: CUSTOM_REPORT_CHART_SETTINGS_FORM_ID,
          isLoading:
            isCreateCustomReportChartSettingsLoading ||
            isUpdateCustomReportChartSettingsLoading,
        },
        onDelete: () => deleteCustomReportChartSettings(initialSettings),
        renderEditingContent: () => (
          <CustomReportChartSettingsForm
            {...{
              customReport,
              customReportLaunchResult,
              chartSettings: initialSettings,
              ...customReportSettingsFormProps,
            }}
          />
        ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
        <CustomReportChart
          {...{
            customReportLaunchResult,
            chartData: currentReportData,
            chartSettings: requestedSettings,
          }}
        />
      </Skeleton>
    </ReportCardBuilderCard>
  );
};
